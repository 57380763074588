import React from 'react'
import classNames from 'classnames'
import styles from './Table.module.css'

const Table = ({ className, children, ...props }: { className?: string; children?: React.ReactNode }) => (
  <table className={classNames(styles.table, className)} {...props}>
    {children}
  </table>
)

export default Table
