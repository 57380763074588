import { useLazyQuery } from '@apollo/client'
import isEqual from 'lodash.isequal'
import sortBy from 'lodash.sortby'

import { statesAndRegionsAUOptions, statesAndRegionsNZOptions } from 'lib/countries'

import GET_FREE_SHIPPING_SUMMARY from './graphql/GetFreeShippingSummary.graphql'
import {
  GetFreeShippingSummary,
  GetFreeShippingSummaryVariables,
  GetFreeShippingSummary_marketplaceCatalogs_nodes_shippingRules as ShippingRule
} from './graphql/__generated__/GetFreeShippingSummary'

import { ShippingRuleCalculationMethodEnum } from '../../../../../../__generated__/globalTypes'

export type GetFreeShippingArgs = {
  country: string
  regions: string[]
  freeShippingOver: number
}

type UseFreeShippingOverBadge = {
  catalogId: string
}

const useFreeShippingOverBadge = ({ catalogId }: UseFreeShippingOverBadge) => {
  const [getFreeShippingSummary, freeShippingRulesResult] = useLazyQuery<
    GetFreeShippingSummary,
    GetFreeShippingSummaryVariables
  >(GET_FREE_SHIPPING_SUMMARY, {
    variables: {
      catalogId
    }
  })

  // Returns an array of freeShippingOver summary for each shipping rule
  const summarizeFreeShippingOverData = (shippingRules: ShippingRule[]) => {
    const result: GetFreeShippingArgs[] = []

    shippingRules.forEach(rule => {
      const { shippingRegions, shippingRuleRates } = rule

      const regionNames = getShippingCoverage(shippingRegions)

      const freeShippingRate = shippingRuleRates.find(rate =>
        rate.rateTable?.some(row => row.calculationMethod === ShippingRuleCalculationMethodEnum.FREE)
      )

      const freeShippingRow = freeShippingRate?.rateTable?.find(
        row => row.calculationMethod === ShippingRuleCalculationMethodEnum.FREE
      )

      const freeShippingOver = freeShippingRow?.min

      result.push({
        country: shippingRegions[0]?.country,
        regions: regionNames,
        freeShippingOver: Math.round(Number(freeShippingOver)) ?? 0
      })
    })

    const sortedResult = sortBy(result, rule => rule.country)

    return sortedResult
  }

  // Returns an array of regions covered by the shipping rule, or a summary of the coverage
  const getShippingCoverage = (shippingRegions: ShippingRule['shippingRegions']) => {
    const country = shippingRegions[0]?.country
    const AURegionsCount = statesAndRegionsAUOptions.flatMap(region => region.options).slice(1).length

    const NZNorthIsland = statesAndRegionsNZOptions[1].options.slice(1).map(opt => opt.label)
    const NZSouthIsland = statesAndRegionsNZOptions[2].options.slice(1).map(opt => opt.label)
    const NZRegionsCount = NZNorthIsland.length + NZSouthIsland.length

    const regions = shippingRegions.map(region => region.name)

    if (country === 'AU') {
      return shippingRegions.length === AURegionsCount ? ['Australia Wide'] : regions
    }

    if (country === 'NZ') {
      if (shippingRegions.length === NZRegionsCount) return ['New Zealand Wide']
      if (isEqual(NZNorthIsland, regions)) return ['North Island']
      if (isEqual(NZSouthIsland, regions)) return ['South Island']
    }

    return regions
  }

  return {
    getFreeShippingSummary,
    freeShippingRulesResult,
    summarizeFreeShippingOverData,
    getShippingCoverage
  }
}

export default useFreeShippingOverBadge
