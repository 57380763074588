import React from 'react'
import { useState, MouseEvent } from 'react'
import classNames from 'classnames'

import Icon from 'components/Icon'
import Badge from 'components/Badge'
import Table from 'components/Table'
import FetchingPanel from 'components/FetchingPanel'
import ModalContainer from 'components/ModalContainer'
import { ModalContainerProps } from 'components/ModalContainer/ModalContainer'
import useFreeShippingOverBadge from './useFreeShippingOverBadge'

import styles from './FreeShippingOverBadge.module.css'

const cx = classNames.bind(styles)

type FreeShippingOverBadgeProps = {
  className?: string
  catalogId?: string
  freeShippingOver: number | undefined | null
  freeShippingIsConditional: boolean
  currencyCode?: string | null
} & Omit<ModalContainerProps, 'isOpen' | 'onExit'>

const FreeShippingOverBadge = ({
  className,
  catalogId = '',
  freeShippingOver,
  freeShippingIsConditional,
  currencyCode,

  ...props
}: FreeShippingOverBadgeProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const { getFreeShippingSummary, freeShippingRulesResult, summarizeFreeShippingOverData } = useFreeShippingOverBadge({
    catalogId
  })

  const shippingRules = freeShippingRulesResult.data?.marketplaceCatalogs.nodes[0]?.shippingRules || []
  const rules = summarizeFreeShippingOverData(shippingRules)

  const formatCurrency = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currencyCode || 'AUD'
  })

  const handleCloseModal = async (e?: MouseEvent<HTMLDivElement> | MouseEvent<HTMLAnchorElement>) => {
    e?.preventDefault()
    e?.stopPropagation()

    setIsOpen(prev => !prev)
    await getFreeShippingSummary()
  }

  return (
    <>
      {freeShippingOver != null && (
        <div
          className={cx(
            styles.freeShippingOverContainer,
            { [styles.notClickable]: !catalogId || !freeShippingIsConditional },
            className
          )}
          onClick={e => handleCloseModal(e)}>
          <Badge
            className={classNames(styles.freeShippingBadge)}
            style="tinted"
            leadingIcon="truck"
            leadingIconColor="var(--colorGreen8)">
            Free Shipping{freeShippingOver > 0 ? ` over ${formatCurrency.format(Math.round(freeShippingOver))}` : null}
            {freeShippingIsConditional ? '*' : ''}
          </Badge>

          <ModalContainer {...props} className={styles.modalContainer} isOpen={isOpen} onExit={handleCloseModal}>
            <div className={styles.modalHeader}>
              <div>
                <h4 className={styles.header}>Free Shipping Details</h4>
              </div>
              <div>
                <nav>
                  <a className={styles.modalClose} onClick={event => handleCloseModal(event)}>
                    <Icon kind="x" size={16} />
                  </a>
                </nav>
              </div>
            </div>

            {freeShippingRulesResult.loading ? (
              <FetchingPanel />
            ) : (
              <Table className={styles.table}>
                <thead>
                  <tr>
                    <th className={styles.tableHeadCell}>
                      <span className="typography-nano">Regions</span>
                    </th>
                    <th className={styles.tableHeadCell}>
                      <span className="typography-nano">Shipping</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rules.map((rule, i) => {
                    const freeAbove = Number(rule.freeShippingOver)
                    return (
                      <tr key={i}>
                        <td className={styles.tableCell}>{rule.regions.join(', ')}</td>
                        <td className={styles.tableCell}>
                          {freeAbove === 0 && <>Free</>}
                          {freeAbove > 0 && <>Free on orders above {formatCurrency.format(freeAbove)}</>}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            )}
          </ModalContainer>
        </div>
      )}
    </>
  )
}

export default FreeShippingOverBadge
